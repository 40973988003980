import React from "react";
import "./DateContainer.css";

export default function DateContainer(props) {
  return (
    <div>
      <div className="event">
        <div className="calendar">
          <div className="date_box">
            <span className="day_name">{props.dayName}</span>{" "}
            <span className="day">{props.day}</span>{" "}
            <span className="month">{props.month}</span>
          </div>
        </div>
        {props.soon && <div className="come_soon">{props.soon}</div>}
        <div className="event_desc_block">
          <p className="city">{props.city}</p>
          {props.eventDesc && <p className="event_desc">{props.eventDesc}</p>}
        </div>
        <div className="warning">
          {props.confirm && <p className="date_confirm">{props.confirm}</p>}
        </div>
      </div>
    </div>
  );
}
