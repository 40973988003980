import React from "react";
import Slider from "../Component/Slider.js";

export default function HomeScreen() {
  return (
    <div>
      <div className="slider">
        <Slider />
      </div>
      <div className="description">
        <div className="first-part">
          <div className="first-line-part">
            <h1>
              Concerts,
              <br /> Festivals,
              <br />
              Concerts privés,
              <br />
              Événementiel
            </h1>
            <h2>
              Une quinzaine de femmes
              <br />
              regroupées autour
              <br />
              <strong>
                de l'amour de la musique
                <br />
                et du chant polyphonique
              </strong>
            </h2>
          </div>
          <main>
            <h3>
              Les Dames de Choeur vivent leur passion du chant avec enthousiasme
              et ferveur
            </h3>
            <p>
              L'amour de la musique et du chant polyphonique est un lien fort
              qui les unit. Placées sous la direction de{" "}
              <a href="/">Marie-Cécile Robin-Héraud</a>, artiste lyrique de
              talent, reconnue pour son exigence vocale, les Dames de Choeur
              n'ont pour seul objectif que de
              <strong> charmer et séduire un vaste public.</strong>
              <br />
              Le répertoire couvre une large période de la{" "}
              <strong>Musique classique</strong>: Rossini, Verdi, Faure,
              Saint-Saëns, Berlioz, Delibes, Brahms... Ces Dames ont aussi à
              coeur de vous faire découvrir les{" "}
              <strong>
                Chants traditionnels d'Europe, les chants hébraïques
              </strong>{" "}
              ainsi que des formes de{" "}
              <strong>polyphonies vocales les plus inattendues...</strong>
              <br />
              Les concerts sont accompagnés au piano par{" "}
              <strong>Rose Réglat</strong>
              &nbsp;ou <strong>Michael Geyre</strong>.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
}
