import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Logo from "../images/logo-3.png";
import "./Header.css";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [icon, setIcon] = useState(faBars);
  const [iconPadding, setIconPadding] = useState(175);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIcon(isMenuOpen ? faBars : faTimes);
    setIconPadding(isMenuOpen ? 265 : 215);
  };

  const menuClass = isMenuOpen ? "homeHeader menuOpen" : "homeHeader";
  return (
    <header className={menuClass}>
      <div className="logo">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <FontAwesomeIcon
        icon={icon}
        className="barsIcon"
        onClick={toggleMenu}
        style={{
          paddingLeft: `${icon === faTimes ? iconPadding : 265}px`,
          color: `${icon === faTimes ? "grey" : ""}`,
        }}
      />
      <nav className="headerNav">
        <ul>
          <li>
            <Link to="/" id="accueil" className="link_nav" onClick={toggleMenu}>
              Accueil
            </Link>
          </li>
          <li>
            <Link
              to="/agenda"
              id="agenda"
              className="link_nav"
              onClick={toggleMenu}
            >
              Agenda
            </Link>
          </li>
          <li id="photos">
            <span className="link_nav">Photos & vidéos</span>
            <nav className="photoNav">
              <ul>
                <li>
                  <Link
                    to="/notre_cheffe"
                    className="link_nav link_nav_1"
                    onClick={toggleMenu}
                  >
                    Notre cheffe
                  </Link>
                </li>
                <li>
                  <Link
                    to="/nos_musiciens"
                    className="link_nav link_nav_2"
                    onClick={toggleMenu}
                  >
                    Nos musiciens
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dames_de_choeur"
                    className="link_nav link_nav_3"
                    onClick={toggleMenu}
                  >
                    Les dames de choeur
                  </Link>
                </li>
              </ul>
            </nav>
          </li>

          <li>
            <Link
              to="/nos_partenaires"
              id="partenaires"
              className="link_nav"
              onClick={toggleMenu}
            >
              Partenaires et amis
            </Link>
          </li>
          <li>
            <Link
              to="/Contact"
              id="contact"
              className="link_nav"
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
