import React, { useState } from "react";
import BannerTitle from "../Component/BannerTitle";
import "./NotreCheffeScreen.css";
import PhotoContainer from "../Component/PhotoContainer";
import MarieCecile from "../images/marie_cecile_1.jpeg";
import MarieCecile2 from "../images/marie_cecile_2.jpg";
import MarieCecile3 from "../images/marie_cecile_3.jpg";
import MarieCecile4 from "../images/marie_cecile_4.jpg";

export default function NotreCheffeScreen() {
  const [zoomedImage, setZoomedImage] = useState(null);
  const zoomImg = (imgSrc) => {
    console.log(imgSrc);
    setZoomedImage(imgSrc);
  };
  const closeZoomImg = () => {
    setZoomedImage(null);
  };
  return (
    <div>
      <BannerTitle name="Notre cheffe" />
      <div id="grid_1">
        <PhotoContainer
          image={MarieCecile}
          name="Marie-Cécile"
          onClick={() => zoomImg(MarieCecile)}
        />
        <div className="grid_desc_1">
          <h3>Marie-Cécile Robin-Héraud</h3>
          <h4>Artiste lyrique, comédienne et cheffe de choeur</h4>
          <p>- Barber Shop Quartet</p>
          <p>- Choeur de l'Opéra de Bordeaux</p>
          <p>- Autour de Minuit</p>
          <p>- Les zingarelles</p>
          <p>- Quatuor Philéas</p>
          <p>- Dames de Choeur</p>
        </div>
        <PhotoContainer
          image={MarieCecile2}
          name="Marie-Cécile"
          onClick={() => zoomImg(MarieCecile2)}
        />
        <PhotoContainer
          image={MarieCecile3}
          name="Marie-Cécile"
          onClick={() => zoomImg(MarieCecile3)}
        />
        <img
          src={MarieCecile4}
          alt="Marie-Cécile"
          id="Marie-Cecile-4"
          onClick={() => zoomImg(MarieCecile4)}
        ></img>
      </div>
      {zoomedImage && (
        <div className="zoom-overlay" onClick={() => closeZoomImg(zoomedImage)}>
          <div className="zoomed-img-container">
            <img src={zoomedImage} alt="zoomed_image" className="zoomed-img" />
          </div>
        </div>
      )}
    </div>
  );
}
